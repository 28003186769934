import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        redirect: '/loginPage'
    },
    {
        name: 'loginPage',
        title: '登录',
        path: '/loginPage',
        component: () => import("@/page/login/loginPage.vue")
    },
    {
        name: 'navPage',
        path: '/navPage',
        component: () => import("@/page/nav/navPage.vue"),
        children: [
            {
                name: 'homePage',
                title: '首页',
                path: '',
                index: '0',
                icon: 'el-icon-reading',
                component: () => import("@/page/home/homePage.vue")
            },
            {
                name: 'billPage',
                title: '账单记录',
                path: '/billPage',
                index: '11',
                icon: 'el-icon-reading',
                component: () => import("@/page/bill/billPage.vue")
            },
            {
                name: 'businessReport',
                title: '营业报表',
                path: '/businessReport',
                index: '13',
                icon: 'el-icon-reading',
                component: () => import("@/page/bill/businessReport.vue")
            },
            {
                name: 'billDetails',
                title: '收银报表',
                path: '/billDetails',
                index: '12',
                icon: 'el-icon-reading',
                component: () => import("@/page/bill/billDetails.vue")
            },
            {
                name: 'classifiedSales',
                title: '收银报表',
                path: '/classifiedSales',
                index: '12',
                icon: 'el-icon-reading',
                component: () => import("@/page/bill/classifiedSales.vue")
            },
            {
                name: 'singleItemSales',
                title: '单品销售',
                path: '/singleItemSales',
                index: '12',
                icon: 'el-icon-reading',
                component: () => import("@/page/bill/singleItemSales.vue")
            },
            {
                name: 'salesDaySummary',
                title: '销售日汇总',
                path: '/salesDaySummary',
                index: '12',
                icon: 'el-icon-reading',
                component: () => import("@/page/bill/salesDaySummary.vue")
            },
            {
                name: 'salesTop',
                title: '销售TOP',
                path: '/salesTop',
                index: '12',
                icon: 'el-icon-reading',
                component: () => import("@/page/bill/salesTop.vue")
            },
            {
                name: 'userInfo',
                title: '个人中心',
                path: '/userInfo',
                index: '14',
                icon: 'el-icon-reading',
                component: () => import("@/page/base/userInfo.vue")
            },
            {
                name: 'changePwd',
                title: '修改密码',
                path: '/changePwd',
                index: '15',
                icon: 'el-icon-reading',
                component: () => import("@/page/base/changePwd.vue")
            },
            {
                name: 'purchaseOrder',
                title: '采购订单',
                path: '/purchaseOrder',
                index: '16',
                icon: 'el-icon-reading',
                component: () => import("@/page/purchaseOrder/purchaseOrder.vue")
            },
            {
                name: 'customerSummary',
                title: '客户汇总',
                path: '/customerSummary',
                index: '17',
                icon: 'el-icon-reading',
                component: () => import("@/page/purchaseOrder/customerSummary.vue")
            },
            {
                name: 'itemSummary',
                title: '品项汇总',
                path: '/itemSummary',
                index: '18',
                icon: 'el-icon-reading',
                component: () => import("@/page/purchaseOrder/itemSummary.vue")
            },
            {
                name: 'salesRecords',
                title: '销售记录',
                path: '/salesRecords',
                index: '19',
                icon: 'el-icon-reading',
                component: () => import("@/page/salesManager/salesRecords.vue")
            },
            {
                name: 'productSalesSummary',
                title: '单品销售汇总',
                path: '/productSalesSummary',
                index: '20',
                icon: 'el-icon-reading',
                component: () => import("@/page/salesManager/productSalesSummary.vue")
            },
            {
                name: 'customSalesSummary',
                title: '客户销售汇总',
                path: '/customSalesSummary',
                index: '21',
                icon: 'el-icon-reading',
                component: () => import("@/page/salesManager/customSalesSummary.vue")
            },
            {
                name: 'departmentSalesSummary',
                title: '部门销售汇总',
                path: '/departmentSalesSummary',
                index: '22',
                icon: 'el-icon-reading',
                component: () => import("@/page/salesManager/departmentSalesSummary.vue")
            },
            {
                name: 'productFile',
                title: '商品档案',
                path: '/productFile',
                index: '15',
                icon: 'el-icon-reading',
                component: () => import("@/page/baseData/product/productFile.vue")
            },
            {
                name:'productCategory',
                title: '商品分类',
                path: '/productCategory',
                index: '23',
                icon: 'el-icon-reading',
                component: () => import("@/page/baseData/product/productCategory.vue")
            },
            {
                name:'accountingDepartment',
                title: '核算部门',
                path: '/accountingDepartment',
                index: '24',
                icon: 'el-icon-reading',
                component: () => import("@/page/baseData/product/accountingDepartment.vue")
            },
            {
                name:'priceType',
                title: '价格类型',
                path: '/priceType',
                index: '25',
                icon: 'el-icon-reading',
                component: () => import("@/page/baseData/customer/priceType.vue")
            },
            {
                name:'customerFile',
                title: '客户档案',
                path: '/customerFile',
                index: '26',
                icon: 'el-icon-reading',
                component: () => import("@/page/baseData/customer/customerFile.vue")
            },
            {
                name:'customerType',
                title: '客户类型',
                path: '/customerType',
                index: '31',
                icon: 'el-icon-reading',
                component: () => import("@/page/baseData/customer/customerType.vue")
            },
            {
                name:'customerRegion',
                title: '客户区域',
                path: '/customerRegion',
                index: '32',
                icon: 'el-icon-reading',
                component: () => import("@/page/baseData/customer/customerRegion.vue")
            },
            {
                name:'customerOperationsCenter',
                title: '客户运营中心',
                path: '/customerOperationsCenter',
                index: '27',
                icon: 'el-icon-reading',
                component: () => import("@/page/baseData/customer/customerOperationsCenter.vue")
            },
            {
                name:'goodsClassification',
                title: '要货分类',
                path: '/goodsClassification',
                index: '28',
                icon: 'el-icon-reading',
                component: () => import("@/page/baseData/product/goodsClassification.vue")
            },
            {
                name:'deliveryDepartment',
                title: '发货部门',
                path: '/deliveryDepartment',
                index: '29',
                icon: 'el-icon-reading',
                component: () => import("@/page/baseData/product/deliveryDepartment.vue")
            },
            {
                name:'supplierList',
                title: '供应商',
                path: '/supplierList',
                index: '30',
                icon: 'el-icon-reading',
                component: () => import("@/page/baseData/other/supplierList.vue")
            },
            {
                name:'paymentMethod',
                title: '付款方式',
                path: '/paymentMethod',
                index: '31',
                icon: 'el-icon-reading',
                component: () => import("@/page/baseData/other/paymentMethod.vue")
            },
            {
                name:'storePictures',
                title: '门店图片管理',
                path: '/storePictures',
                index: '32',
                icon: 'el-icon-reading',
                component: () => import("@/page/baseData/other/storePictures.vue")
            },
            {
                name:'posSite',
                title: 'POS站点',
                path: '/posSite',
                index: '33',
                icon: 'el-icon-reading',
                component: () => import("@/page/systemSetting/posSite.vue")
            }
        ]
    }
]
const router = new VueRouter({
    routes,
})
router.beforeEach((to, from, next) => {
    next()
})
export default router